<template>
	<van-nav-bar :title="title" :style="styles">
		<div slot="left" class="NavBar_left">
			<div @click="goLeftLink" v-if="isShowLeft">
				<img style="margin-bottom:-1px;" width="8" height="14" :src="leftIcon" alt="返回" />
				返回
			</div>
		</div>
		<div class="tc" v-if="(title == '')" slot="title" style="color:#030303;">
			<img style="margin-bottom:-4px;" height="20" class="logo" :src="img.logo" alt="淘街坊" />
		</div>
		<div slot="right" class="NavBar_right">
			<slot name="right_content"></slot>
		</div>
	</van-nav-bar>
</template>

<script>
import Vue from 'vue'
import { NavBar } from 'vant'
Vue.use(NavBar)

export default {
  name: 'NavBar',
  props: {
    bgColor: {
      type: String,
      default: '#f8f8f8'
    },
    title: {
      type: String,
      default: ''
    },
    isShowLeft: {
      type: Boolean,
      default: false
    },
    leftIcon: {
      type: String,
      default: require('@/assets/common/back_btn.png')
    },
    leftLink: {
      type: String,
      default: '/'
    }
  },
  data () {
    return {
      styles: 'background:' + this.bgColor,
      img: {
        logo: require('@/assets/common/logo.png')
      }
    }
  },
  methods: {
    goLeftLink: function () {
      if (this.leftLink == '/') {
        this.$router.go(-1)
      } else {
        this.$router.push(this.leftLink)
      }
    }
  }
}
</script>

<style scope>
.NavBar_left a,.NavBar_right a{font-size:16px;color:#555;line-height:40px;}
</style>
