<template>
	<div id="container" @click="handler">
    <template v-if="!!realLink">
      <router-link :to="realLink">
        <p class="btn" :style="realBgColor">{{text}}</p>
      </router-link>
    </template>
    <template v-else>
      <p class="btn" :style="realBgColor">{{text}}</p>
    </template>
	</div>
</template>

<script>
export default {
  name: 'PinkBtn',
  props: {
    bgColor: {
      type: String,
      default: '#ff6c7b'
    },
    link: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: '常用按钮'
    },
    disable: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    realBgColor: function () {
      if (this.disable) {
        return 'background:#ccc;box-shadow:3px 5px 15px #f2f2f2;color:#fff;'
      } else if (this.transparent) {
        return 'background:transparent;border:solid 1px #ff6c7b;box-shadow:3px 5px 15px #f2f2f2;color:#ff6c7b;font-weight:normal;'
      } else {
        return 'background:' + this.bgColor
      }
    },
    realLink: function () {
      return this.disable ? '' : this.link
    }
  },
  methods: {
    handler: function () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.btn{margin:0 auto;padding:10px 0;border-radius:60px;box-shadow:3px 5px 15px #fcd3e4;display:flex;justify-content:center;align-items:center;color:#fff;font-weight:bold;font-size:15px;}
</style>
