<template>
  <div class="page_body">
    <div class="account_infomation">
      <div class="account">
        <p>实名认证</p>
        <span>为有效保障您的资金安全，提升订单处理效率，请先进行实名认证吧</span>
      </div>
      <div class="identityCard">
        <div class="card_img">
          <p>身份证正面</p>
          <van-uploader
            :disabled="upload_disable"
            v-model="justfile"
            :after-read="just_afterRead"
            multiple
            max-count="1"
            class="img"
          />
        </div>
        <div class="card_img">
          <p>身份证反面</p>
          <van-uploader
            v-model="againstfile"
            :after-read="against_afterRead"
            multiple
            max-count="1"
            class="img"
          />
        </div>
        <div class="hint">请确认身份信息，如有误请修改</div>
        <van-button @click="submit">提交认证</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import StatusBar from "@/components/StatusBar";
import NavBar from "@/components/NavBar";
import ls from "@/libs/localstore";
import { Picker, Cell, Button, Uploader } from "vant";
import PinkBtn from "@/components/PinkBtn";
import { uploadPic } from "@/api/upload";
import { getQNYToken, submitIdentityCard } from "@/api/wallet";

Vue.use(Picker).use(Cell).use(Button).use(Uploader);

require("@/libs/validate.js");

export default {
  name: "WithDrawCashApply",
  components: {
    StatusBar,
    NavBar,
    PinkBtn,
  },
  data() {
    return {
      uploadHost: "",
      imageHost: "",
      videoHost: "",
      justfile: [],
      idcard_front_image: "",
      againstfile: [],
      idcard_back_image: "",
      upload_disable: false,
    };
  },
  mounted() {
    this._getQNYToken();
  },
  methods: {
    //提交
    submit() {
      let params = {
         
        idcard_front_image: this.idcard_front_image,
        idcard_back_image: this.idcard_back_image,
      };
      submitIdentityCard(params).then(res=>{
          if(res.errCode==0){
            this.$toast.success('上传成功');
            this.$router.push('/uploadSuccess')
          }
      }).catch(err=>{
          this.$toast.fail(err.errMsg)
      })
    },
    //七牛云
    _getQNYToken() {
      let params = {
         
      };
      getQNYToken(params).then((res) => {
        if (res.errCode == 0) {
          this.token = res.data.token;
          this.imageHost = res.data.image_host;
          this.uploadHost = res.data.upload_host;
        }
      });
    },
    against_afterRead(file) {
      let params = new FormData(); // 创建form对象
      params.append("file", file.file);
      params.append("token", this.token);
      let config = {
        timeout: 8000,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: this._uploadProgress,
      };
      this.upload_disable = true;
      uploadPic(this.uploadHost, params, config)
        .then((res) => {
          this.upload_disable = false;
          if (res.key) {
            this.idcard_back_image = this.imageHost + res.key;
          }
        })
        .catch((err) => {
          this.upload_disable = false;
          this.$toast("上传的文件有误");
        });
    },
    _uploadProgress(progressEvent) {},
    just_afterRead(file) {
      let params = new FormData(); // 创建form对象
      params.append("file", file.file);
      params.append("token", this.token);
      let config = {
        timeout: 8000,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: this._uploadProgress,
      };
      this.upload_disable = true;
      uploadPic(this.uploadHost, params, config)
        .then((res) => {
          this.upload_disable = false;
          if (res.key) {
            this.idcard_front_image = this.imageHost + res.key;
          }
        })
        .catch((err) => {
          this.upload_disable = false;
          this.$toast("上传的文件有误");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #ffffff;
}
.account_infomation {
  .account {
    margin-bottom: 10px;
    width: 375px;
    height: 121px;
    background: linear-gradient(
      270deg,
      rgba(255, 108, 115, 1),
      rgba(255, 85, 149, 1)
    );
    color: rgba(255, 255, 255, 1);
    font-family: PingFang SC;
    padding: 24px 18.5px 27px;
    box-sizing: border-box;
    p {
      line-height: 19px;
      font-size: 19px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    span {
      font-size: 15px;
      line-height: 22px;
      font-weight: 400;
    }
  }
  .identityCard {
    .card_img {
      margin-left: 62.5px;
      p {
        font-size: 14px;
        color: #555555;
        line-height: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        margin: 24.5px 0 10px;
      }
      .img {
        width: 250px;
        height: 157px;

        z-index: 99;
      }
      .van-uploader {
        width: 250px;
        height: 157px;
        .van-uploader__upload {
          width: 250px;
          height: 157px;
          margin: 0;
          background: url(../../../assets/common/tjf_gzh_gwfl_sqtx_smrz_sfzfm_img.png)
            no-repeat;
          background-size: 100% 100%;
          .van-uploader__upload-icon {
            display: none;
          }
        }
        .van-uploader__preview {
          width: 250px;
          height: 157px;
          margin: 0;
          .van-image {
            width: 250px;
            height: 157px;
          }
          img {
            width: 250px;
            height: 157px;
          }
        }
        .van-uploader__input {
          width: 250px;
          height: 157px;
        }
      }
    }
    .hint {
      text-align: center;
      margin: 32px 0 24px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(241, 159, 10, 1);
    }
    .van-button {
      width: 337px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      font-size: 17px;
      color: #fff;
      background: linear-gradient(
        270deg,
        rgba(255, 108, 115, 1),
        rgba(255, 85, 149, 1)
      );
      border-radius: 22.5px;
      margin: 0 19px;
    }
  }
}
</style>
