// 收入明细
export const incomeDetail = (params) => window._http('/User-profitAndLoss', params)

// 提现记录
export const withDrawCash = (params) => window._http('/Rebate-list', params)

// 申请提现
export const getWithdrawTimes = (params) => window._http('/Rebate-getBrokerage', params)

// 申请提现
export const withDrawCashApply = (params) => window._http('/Rebate-add', params)
//订单详情
export const getUnionBill = (params) => window._http('/UnionBill-index', params)
// 获取客服详细信息
export const getCustomerInfo = () => window._http('/Customer-accountInfo')
//七牛云token
export const getQNYToken = (params) => window._http('Upload-getToken', params)
//提交身份证审核
export const submitIdentityCard = (params) => window._http('IdcardReview-add', params)
//查询身份证审核状态
export const IdcardReview =(params) => window._http('IdcardReview-item',params)
