<template>
	<div>
		<div id="status_bar" :style="styles"></div>
	</div>
</template>

<script>
export default {
  name: 'StatusBar',
  props: {
    bgColor: {
      type: String,
      default: 'transparent'
    }
  },
  data () {
    return {
      styles: 'background:' + this.bgColor
    }
  }
}
</script>

<style scoped>
#status_bar{height:20px;min-height:20px;}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3){
	#status_bar{height:32px;}
}
</style>
